import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  GoogleAuthProvider,
  User,
  getAdditionalUserInfo,
  getRedirectResult,
  onAuthStateChanged,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

import { auth } from "./lib/firebase";

import "./style.css";

function Home() {
  const { user, setUser } = React.useContext(AuthContext);
  async function signIn() {
    const googleProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleProvider);
  }
  async function handleSignOut() {
    try {
      signOut(auth)
        .then((result) => {
          console.log(result);
          setUser(null);
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  }
  console.log({ user });
  return (
    <div>
      <h2 className="text-2xl">Home Page</h2>
      {!!user ? (
        <>
          {user.displayName}
          <button onClick={handleSignOut} type="button">
            Sign out
          </button>
        </>
      ) : (
        <button onClick={signIn} type="button">
          Sign in with Google
        </button>
      )}
    </div>
  );
}

function About() {
  return <h2 className="text-2xl">About Page</h2>;
}

const AuthContext = React.createContext<{
  user: null | User;
  setUser: (u: User | null) => void;
}>({ user: null, setUser: (u) => {} });

function App() {
  const [user, setUser] = React.useState<User | null>(null);

  const updateUser = async () => {
    onAuthStateChanged(auth, (user) => {
      if (user) setUser({ ...user });
    });

    try {
      const result = await getRedirectResult(auth);
      if (result) {
        const details = getAdditionalUserInfo(result);
        console.log(details); // details.isNewUser to determine if a new or returning user
      } else {
        // Everything is fine
      }
    } catch (error) {
      console.log(error); // Debug errors from redirect response
    }
  };

  React.useEffect(() => {
    updateUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <div className="text-center">
          <nav className="mb-4">
            <Link to="/" className="mr-4 text-blue-500">
              Home
            </Link>
            <Link to="/about" className="text-blue-500">
              About
            </Link>
          </nav>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

const rootRender = async () => {
  try {
    const container = document.getElementById("root");
    const root = createRoot(container!);
    root.render(<App />);
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

rootRender();
